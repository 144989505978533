import styled from "styled-components";
import {ImpactSubText, SubText} from "../../components/common/Texts";
import {SecondaryButtonSmall} from "../../components/common/Buttons";
import {Trans, useTranslation} from "react-i18next";
import {GoodbyePageComponent} from "../goodbye";
import {useMemo} from "react";
import {useWalletContext} from "../../contexts/wallet.context";

function Parse(e?: ErrorData): { title: string, description: string } {
    switch (e?.type) {
        case "BAD_REQUEST":{
            return {
                title: 'ErrorBadRequest',
                description: 'ErrorDefaultDesc'
            }
        }
        default: {
            return {
                title: 'ErrorBadRequest',
                description: 'ErrorDefaultDesc'
            }
        }
    }
}

const ErrorPageComponent = styled(GoodbyePageComponent)`
    >div{
      >${SubText}{
        text-align: center;
        
        padding: 20px 0;
      }
    }
`

export default function ErrorPage() {
    const { t } = useTranslation()
    const { state: { error }, dispatch } = useWalletContext()
    const { title, description } = useMemo(() => Parse(error), [error])

    return (
        <ErrorPageComponent>
            <div>
                <ImpactSubText>
                    { t(title) }
                </ImpactSubText>
                <SubText>
                    <Trans i18nKey={description} />
                </SubText>
                <SecondaryButtonSmall onClick={ dispatch.bind(this, {
                    type: 'setError', value: undefined
                }) as any }>
                    { t('btnClose') }
                </SecondaryButtonSmall>
            </div>
        </ErrorPageComponent>
    )
}