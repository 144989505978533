// src/styles/media.ts
import {css, CSSObject, SimpleInterpolation} from "styled-components";

type DeviceType = "desktop" | "tablet" | "mobile" | "fold";

export const sizes: Record<DeviceType, number> = {
    desktop: 1200,
    tablet: 900,
    mobile: 480,
    fold: 300
};

const media = Object.entries(sizes).reduce((acc, [key, value]) => {
    return {
        ...acc,
        [key]: (
            first: CSSObject | TemplateStringsArray,
            ...interpolations: SimpleInterpolation[]
        ) => css`
      @media screen and (max-width: ${value}px) {
        ${first}
        ${css({}, ...interpolations)}
      }
    `,
    };
}, {}) as Record<DeviceType, any>;

const limit = Object.entries(sizes).reduce((acc, [key, value]) => {
    return {
        ...acc,
        [key]: (
            first: CSSObject | TemplateStringsArray,
            ...interpolations: SimpleInterpolation[]
        ) => css`
      @media screen and (min-width: ${value}px) {
        ${first}
        ${css({}, ...interpolations)}
      }
    `,
    };
}, {}) as Record<DeviceType, any>;

export { media, limit };