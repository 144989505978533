import styled from "styled-components";
import {PageLayout} from "../../styles/layout";
import {ImpactSubText} from "../../components/common/Texts";
import {SecondaryButtonSmall} from "../../components/common/Buttons";
import useWebApp from "../../hooks/useWebApp";
import {useTranslation} from "react-i18next";

export const GoodbyePageComponent = styled(PageLayout)`
  background: rgba(0, 0, 0, 0.6);

  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  >div{
    width: 200px;
    background: #fff;
    border-radius: 10px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    flex-direction: column;
    
    ${ImpactSubText}{
      padding: 20px;
    }
    
    ${SecondaryButtonSmall}{
      height: 40px;
    }
  }
`

export default function GoodbyePage() {
    const webApp = useWebApp()
    const { t } = useTranslation()

    return (
        <GoodbyePageComponent>
            <div>
                <ImpactSubText>
                    { t('SessionExpired') }
                </ImpactSubText>
                <SecondaryButtonSmall onClick={ webApp.close as any }>
                    { t('btnClose') }
                </SecondaryButtonSmall>
            </div>
        </GoodbyePageComponent>
    )
}