import React, {useEffect, useState} from 'react'
import WalletProvider from "../contexts/wallet.context";
import {getHttpEndpoint, Network} from "@orbs-network/ton-access";
import CircularProgress from "../components/CircularProgress";

type ProviderType = {
    children: React.ReactNode
}

const Providers = ({children}: ProviderType) => {
    let [endpoint, setEndpoint] = useState<string | undefined>()

    useEffect(() => {
        getHttpEndpoint({
            network: (process.env.REACT_APP_TON_NETWORK ?? 'mainnet') as Network
        }).then(setEndpoint)
    }, [])

    if (!endpoint) {
        return (
            <CircularProgress size={ 50 } strokeWidth={ 5 } />
        )
    }

    return (
        <WalletProvider endpoint={endpoint}>
            { children }
        </WalletProvider>
    )
}

export default Providers
