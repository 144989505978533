import {createGlobalStyle, css} from "styled-components";
import normalize from "styled-normalize";
import {media} from "./media";
import {CircularProgressComponent} from "../components/CircularProgress";

const GlobalStyle = createGlobalStyle`
  ${normalize};
  
  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --font-rules: 'Google Sans', Roboto, 'Noto Sans KR', sans-serif;
    font-family: var(--font-rules);

    //--light-font: 400;
    //--medium-font: 500;
    //--bold-font: 600;
    //--ultra-font: 700;
  }
  
  * {
    box-sizing: border-box;
  }
  
  button{
    border: unset;
    cursor: pointer;
    user-focus: none;

    -webkit-transition: all .15s;
    transition: all .15s;
    
    &:focus{
      outline: none;
    }
  }

  input{
    border: none;
    
    &:focus{
      outline: none;
    }
  }

  ul{
    list-style: none;
    padding: unset;
  }

  ${media.mobile(css`
    html{
      padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    }

    html, body{
      box-sizing: border-box;
      @supports (-webkit-touch-callout: none) {
        --max-width: -webkit-fill-available;
        --max-height: -webkit-fill-available;
      }
    }
  `)};
  
  ${CircularProgressComponent} {
    position: absolute;
    
    margin: auto;
    
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export default GlobalStyle;