import React from "react";
import {createRoot} from 'react-dom/client';
import "./utils/index"
import "./utils/DecimalCalc"
import "./utils/api"

import App from "./App";
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "styled-components";
import {AppTheme} from "./styles/Theme";
import GlobalStyle from "./styles/global-styles";
import Providers from "./providers";
import {BrowserRouter} from "react-router-dom";
import {initTranslation} from "./i18n";
import CircularProgress from "./components/CircularProgress";

const root = createRoot(document.getElementById("root")!);

root.render(
    <div className={'loading-app'}>
        <GlobalStyle />
        <CircularProgress size={ 50 } strokeWidth={5}/>
    </div>
);

initTranslation().then(() => (
    root.render(
        <ThemeProvider theme={AppTheme}>
            <GlobalStyle />
            <Providers>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Providers>
        </ThemeProvider>
    )
))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
