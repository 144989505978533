import {PageLayout, PageWithButtonLayout} from "../../styles/layout";
import styled from "styled-components";
import {Heading} from "../../components/common/Texts";
import {PrimaryButton} from "../../components/common/Buttons";
import InputWithTitle, {InputWithTitleComponent} from "../../components/InputWithTitle";
import {useCallback, useState} from "react";
import {useWalletContext} from "../../contexts/wallet.context";
import {decrypt, getKeyPair} from "../../utils/crypto";
import CircularProgress from "../../components/CircularProgress";
import {useTranslation} from "react-i18next";
import {validatePassword} from "../../utils/validate";

const ButtonContainer = styled.div`
  width: 100%;

  align-items: center;
`

const LoginPageComponent = styled(PageWithButtonLayout)`
  align-items: center;
  justify-content: center;

  >img{
    width: 100px;
    object-fit: contain;
    margin-top: auto;
  }

  ${Heading}{
    margin-top: 40px;
  }

  >${InputWithTitleComponent}{
    margin-top: 90px;
  }

  ${ButtonContainer}{
    display: flex;
    flex-direction: column;

    gap: 10px;

    margin-top: 33px;
  }
`

export default function LoginPage() {
    const { t } = useTranslation()
    const { ton, state, dispatch } = useWalletContext()
    const [password, setPassword] = useState<string | undefined>();
    const [error, setError] = useState<string | undefined>()
    const [loading, setLoading] = useState(false)

    // 개발 편의상
    // useEffect(() => {
    //     if (state.address) {
    //         navigate('/wallet/asset', {
    //             replace: true
    //         })
    //     }
    // }, [state.address])

    const login = useCallback(async (pwd?: string) => {
        if (loading) {
            return
        }

        setError(undefined)
        setLoading(true)

        try {
            if (!state.encrypted || !pwd) return

            let decrypted = await decrypt(state.encrypted, pwd)

            let keyPair = await getKeyPair(ton, decrypted.split(','))

            if (keyPair.address) {
                dispatch({
                    type: 'loadWallet',
                    value: {
                        address: keyPair.address
                    }
                })
            }
        } catch (e: any) {
            console.error(e)
            setError(t('AlertIncorrectPassword'))
        } finally {
            setLoading(false)
        }
    }, [ton, state, loading, setError])

    return (
        <LoginPageComponent>
            <img alt={'logo'} src={'/images/logo/logo-taco.png'} />
            <Heading>{ t('TitleT@connect') }</Heading>
            <InputWithTitle title={t('NeedEnterPassword')} error={error} onChange={ setError.bind(this, undefined) } value={password} setValue={setPassword} onFocus={ setError.bind(this, undefined) } onEnterKey={login} mask/>
            <PrimaryButton onClick={ login.bind(this, password) } disabled={(password?.length ?? 0) < 1}>
                {
                    loading ? (
                        <CircularProgress size={16} strokeWidth={2} />
                    ) : t('btnContinue')
                }
            </PrimaryButton>
        </LoginPageComponent>
    )
}