import {AppThemeBase} from "styled-components";

export const AppTheme: AppThemeBase = {
    basicWidth: '390px',

    colors: {
        primary: '#0767B2',
        text: '#000',
        textImpact: '#222',
        subText: '#898989',
        warning: '#FF8336',
        error: '#E73635',
        button: {
            textPrimary: '#FFF',
            textSecondary: '#0767B2',
            backgroundPrimary: '#0767B2',
            backgroundSecondary: '#E6F0F7',
            hoverPrimary: '#01477D',
            hoverSecondary: '#CEE8FF'
        },
        input: {
            underline: '#D9D9D9',
            background: '#FFF',
            backgroundReadOnly: 'rgba(0, 0, 0, 0.05)',
            backgroundError: 'rgba(255, 0, 0, 0.05)',
            error: '#F00',
            placeholder: '#898989',
        },
        password: {
            difficulty: {
                low: '#E73635',
                medium: '#EAA54A',
                high: '#00AE87'
            }
        },
        qrCode: {
            background: '#D9D9D9'
        }
    },

    fonts: {
        light: 400,
        medium: 500,
        bold: 600,
        ultra: 700
    }
}