import {PageLayout} from "../../styles/layout";
import styled from "styled-components";
import {Heading, ImpactPrimarySubText, SubText} from "../../components/common/Texts";
import {PrimaryButton, SecondaryButton} from "../../components/common/Buttons";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {useWalletContext} from "../../contexts/wallet.context";

const ButtonContainer = styled.div`
    width: 100%;
  
  align-items: center;
`

const WelcomePageComponent = styled(PageLayout)`
  align-items: center;
  justify-content: center;
  
  >img{
    width: 100px;
    object-fit: contain;
  }
  
  ${Heading}{
    margin-top: 40px;
  }
  
  >${SubText}{
    margin-top: 10px;
    text-align: center;
  }

  ${ButtonContainer}{
    display: flex;
    flex-direction: column;
    
    gap: 10px;

    margin-top: 33px;
  }
`

export default function WelcomePage() {
    const { state: { address } } = useWalletContext()
    const { t } = useTranslation()
    const navigate = useNavigate()

    if (address) {
        navigate('/wallet/asset', {
            replace: true
        })
        return null
    }

   return (
       <WelcomePageComponent>
           <img alt={'logo'} src={'/images/logo/logo-taco.png'} />
          <Heading>{ t('TitleT@connect') }</Heading>
          <SubText>
              <Trans i18nKey={'T@connectdesc1'} components={{
                  strong: <ImpactPrimarySubText />
              }} /><br/>
              <Trans i18nKey={'T@connectdesc2'} />
          </SubText>
           <ButtonContainer>
               <PrimaryButton onClick={ () => navigate('/wallet/create') }>{ t('btnCreateNewWallet') }</PrimaryButton>
               <SecondaryButton onClick={ () => navigate('/wallet/import') }>{ t('btnImportingWallet') }</SecondaryButton>
           </ButtonContainer>
       </WelcomePageComponent>
   )
}