import axios from 'axios';
import {configure} from "axios-hooks";

const URL = (process.env.REACT_APP_API_URL ?? window.origin) + '/api';
// const URL = 'http://localhost:3333/api';
export const api = axios.create({
    baseURL: URL,
    responseType: 'json',

    headers: {
        'Content-Type': 'application/json'
    },

    timeout: 5000
    // withCredentials: false
})

export const RetryRequest = async (req: any, webApp: useWebAppValues) => {
    try {
        let [path, initData] = req.responseURL.split('/').pop().split('?')

        let response = await api.get(`/auth/retry?` + initData, {
            headers: {
                type: path
            }
        })

        if (response.status === 200) {
            webApp.close()
        }
    } catch (e) {

    }
}

configure({
    axios: api,
    defaultOptions: {
        useCache: false
    }
})
