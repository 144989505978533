import {lazy, Suspense, useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";

// @ts-ignore
import querystring from "query-string";
import {BackButton, CloseButton} from "./components/common/Buttons";
import {RootLayout} from "./styles/layout";
import useNavigationButton from "./hooks/useNavigationButton";
import {getWallet, useWalletContext} from "./contexts/wallet.context";
import RouteWithWallet, {RouteWithPassPhrase} from "./utils/RouteWithWallet";
import useWebApp from "./hooks/useWebApp";
import useSubPath from "./hooks/useSubPath";
import CircularProgress from "./components/CircularProgress";
import {DeepLinkData} from "./types/tonweb";
import ErrorPage from "./pages/error";

const SignInPage = require("./pages/sign-in").default;
const WelcomePage = require("./pages/welcome").default;
const GoodbyePage = lazy(() => import("./pages/goodbye"));

// const MyWalletPage = lazy(() => import("./pages/wallet/asset/MyPage"));


// wallet
const CreateWalletContainer = lazy(() => import("./pages/wallet/create/Container"));
const ImportWalletContainer = lazy(() => import("./pages/wallet/import/Container"));
const TransactionContainer = lazy(() => import("./pages/wallet/tx/Container"));
const AssetPageContainer = lazy(() => import("./pages/wallet/asset/Container"));

// const CreateWarningPage = lazy() => import("./pages/wallet/create/WarningPage"));
// const CreatedPassPhrasePage = lazy(() => import("./pages/wallet/create/PassPhrasePage"));

export default function App(props: any) {
    const navigate = useNavigate();
    const location = useLocation()
    const [deepLink, setDeepLink] = useState<DeepLinkData | undefined>()
    const { ton, state, dispatch } = useWalletContext()
    const { back, close } = useNavigationButton()
    const {route, path, subPath} = useSubPath()
    const [loading, setLoading] = useState(true)
    const webApp = useWebApp()

    // sign-in에서 넘어올때
    useEffect(() => {
        if (state.address) {
            if (deepLink) {
                navigate(deepLink.path, {
                    replace: true,
                    state: {
                        deepLink: querystring.parse(deepLink.data?.toString())
                    }
                })
            } else {
                navigate('/wallet/asset', {
                    replace: true
                })
            }
        }

        return () => {
            if (state.address) {
                setDeepLink(undefined)
            }
        }
    }, [state.address, deepLink])

    useEffect(() => {
        if (webApp) {
            if (!webApp.isExpanded) {
                webApp.expand()
            }

            webApp.enableClosingConfirmation && webApp.enableClosingConfirmation()
        }
    }, [webApp])

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        if (webApp?.initDataUnsafe?.user?.id) {
            if (state.initialized) return

            getWallet(ton, dispatch, Number(params.get('messageId')) ?? -1, webApp, params.get('chatInstance')).then((response) => {
                if (!response) {
                    setLoading(false)
                    return
                }

                let _deepLink: DeepLinkData = {
                    path: '/' + [route, path, ...subPath].join('/'),
                    data: window.location.search
                }

                if (['/wallet/change-password', '/sign-in/'].includes(_deepLink.path)) {
                    _deepLink.path = '/wallet/asset'
                }

                // console.log({
                //     href: window.location.href,
                //     deepLink: _deepLink
                // })

                setDeepLink(_deepLink)

                const { passphrase, referralCode } = response

                if (!passphrase) {
                    navigate('/welcome', {
                        replace: true
                    })
                } else {
                    dispatch({
                        type: 'setWallet',
                        value: {
                            encrypted: passphrase.trim(),
                            referralCode: referralCode
                        }
                    })

                    if (!['change-password'].includes(path || '')) {
                        navigate('/sign-in', {
                            replace: true
                        })
                    }

                }

                setLoading(false)
            })
        } else {
            // dispatch({
            //     type: 'setWallet',
            //     value: {}
            // })
        }
    }, [webApp?.initDataUnsafe?.user?.id, webApp])

    return (
        <RootLayout>
            <div className={'page-container'}>
                {
                    (loading) ? (
                        <CircularProgress size={ 50 } strokeWidth={ 5 } />
                    ) : (
                        <Suspense fallback={ <CircularProgress size={50} strokeWidth={5} /> }>
                            {
                                back ? (
                                    <BackButton onClick={ () => {
                                        navigate(-1)
                                    } } />
                                ) : null
                            }
                            {
                                close ? (
                                    <CloseButton onClick={ () => webApp?.close() }/>
                                ) : null
                            }
                            {
                                state.error ? (
                                    <ErrorPage />
                                ) : null
                            }
                            {
                                state.sessionExpired ? (
                                    <GoodbyePage />
                                ) : null
                            }
                            <Routes>
                                {/*<Route path="/" element={<MainPage />} />*/}
                                <Route path="/welcome" element={<WelcomePage />} />
                                <Route path="/sign-in" element={
                                    <RouteWithPassPhrase passPhrase={ state.encrypted }>
                                        <SignInPage />
                                    </RouteWithPassPhrase>
                                } />

                                <Route path="/wallet/*">
                                    <Route path="asset/*" element={
                                        <RouteWithWallet address={ state.address } children={ <AssetPageContainer /> } />
                                    } />
                                    <Route path="create/*" element={ <CreateWalletContainer /> } />
                                    <Route path="import/*" element={ <ImportWalletContainer /> } />
                                    <Route path="change-password" element={ <Navigate to={'/wallet/import'} state={{ reset: true }} replace/> } />
                                    <Route path="tx/*" element={
                                        <RouteWithWallet address={ state.address }>
                                            <TransactionContainer />
                                        </RouteWithWallet>
                                    } />
                                    <Route path="*" element={ <Navigate to={'asset'} state={location.state} replace/> } />
                                </Route>

                                <Route path={"*"} element={<Navigate to={'/sign-in'} replace/>} />
                            </Routes>
                        </Suspense>
                    )
                }
            </div>
        </RootLayout>
    )
};