import styled, {css} from "styled-components";
import {SubTextStyle} from "./common/Texts";
import {InputWithTitleProps} from "../types/component";
import React, {ChangeEvent, ForwardedRef, forwardRef, KeyboardEvent, useCallback, useState} from "react";
import {validatePassword} from "../utils/validate";
import {isPromise} from "../utils";

const textStyle = css`
  ${SubTextStyle};

  color: ${ ({ theme }) => theme.colors.text };
`;

const Error = styled.span`
  ${SubTextStyle};
  font-size: 12px;
  color: ${ ({ theme }) => theme.colors.input.error};
`

const ValidationResult = styled.span`
  ${SubTextStyle};

  font-size: 12px;

  display: flex;
  align-items: center;

  gap: 5px;
`

export const Title = styled.span`
  ${textStyle};

  color: ${ ({ theme }) => theme.colors.subText };
  font-weight: ${ ({ theme }) => theme.fonts.ultra };
`

const InputStyle = css<{
    error?: any
}>`
  ${textStyle};

  width: 100%;
  //min-width: 250px;
  height: 40px;
  border-radius: 5px;

  padding: 10px;
  
  ${ ({theme, error}) => error ? css`
    border: 1px solid ${ theme.colors.input.error } !important;

    box-shadow: 0 4px 0 0 ${ theme.colors.input.backgroundError } inset !important;
    background-color: ${ theme.colors.input.backgroundError } !important;
  ` : null };

`

const InputSpan = styled.span`
  ${InputStyle};
  
  line-break: anywhere;
  height: auto;
  
  ${ ({ theme }) => css`
    color: ${ theme.colors.input.placeholder };
    background: ${ theme.colors.input.backgroundReadOnly };
    cursor: not-allowed;
  `}
`

const Input = styled.input`
  ${InputStyle};

  ${ ({ theme }) => css`
    background: ${ theme.colors.input.background };

    border: 1px solid #F2F2F2;
    box-shadow: 0 4px 0 0 #FDFBFB inset;
    
    ::placeholder{
      color: ${ theme.colors.input.placeholder };
    }
  `}
`

export const InputWithTitleComponent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 10px;
`

export default forwardRef(({ children, title, value, setValue, onChange, onEnterKey, onFocus: onFocusFunc, placeholder, validate, description, mask, error: err, readOnly = false }: InputWithTitleProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [error, setError] = useState<string | undefined>(undefined);

    const onFocus = useCallback(() => {
        if (onFocusFunc) {
            onFocusFunc()
        }

        setError(undefined)
    }, [onFocusFunc])

    const onBlur = useCallback(() => {
        if (!value) {
            setError(undefined)
        } else if (validate) {
            let result = validate(value)

            if (isPromise(result)) {
                (result as Promise<string|undefined>).then(setError)
            } else {
                setError(result as (string | undefined))
            }
        }
    }, [value, validate])

    const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (onEnterKey) {
                onEnterKey(value ?? '')
            }
        }
    }, [onEnterKey, value])

    return (
        <InputWithTitleComponent>
            {
                (title || children) ? (
                    <Title>
                        { title ? title : null }
                        { children }
                    </Title>
                ) : null
            }
            {
                readOnly ? (
                    <InputSpan error={ (err ?? error) }>{ value }</InputSpan>
                ) : (
                    // @ts-ignore
                    <Input ref={ref} type={ mask ? 'password' : '' } placeholder={placeholder} value={ value ?? '' } onKeyDown={onKeyDown} onFocus={onFocus} onBlur={onBlur} onChange={ e => {
                        if (onChange) {
                            onChange(e.target.value)
                        }

                        setError(undefined)
                        setValue && setValue(e.target.value)
                    } } error={ err ?? error }/>
                )
            }
            {
                error || err ? (
                    <Error>{ err ?? error }</Error>
                ) : null
            }
        </InputWithTitleComponent>
    )
})

export function PasswordInput({ title, value, setValue, placeholder, onEnterKey, description, mask }: InputWithTitleProps) {
    const [validator, setValidator] = useState<NodeJS.Timeout | null>(null);
    const [validation, setValidation] = useState<PasswordValidationResult | undefined>()

    const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (onEnterKey) {
                onEnterKey(value ?? '')
            }
        }
    }, [onEnterKey, value])

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (validator) {
            clearTimeout(validator)
        }

        setValidator(setTimeout(() => {
            setValidation(validatePassword(e.target.value))
        }, 100))

        setValue && setValue(e.target.value)
    }

    return (
        <InputWithTitleComponent>
            {
                title ? (
                    <Title>{ title }</Title>
                ) : null
            }
            <Input type={ mask ? 'password' : '' } placeholder={placeholder} value={ value ?? '' } onKeyDown={onKeyDown} onChange={ onInputChange } />
            {
                validation?.valid ? (
                    <ValidationResult>
                        <span>Password Strength: </span>
                        <strong>{ validation.difficulty }</strong>
                        <img src={`/images/icon/ic-difficulty-${validation.difficulty}.svg`} alt={'password difficulty'} />
                    </ValidationResult>
                ) : null
            }
        </InputWithTitleComponent>
    )
}