import TonWeb from "tonweb";
import {mnemonicToKeyPair} from "tonweb-mnemonic";

const nacl = TonWeb.utils.nacl;

export async function getKeyPair(ton: TonWeb, words: string[]): Promise<KeyPairFromPassPhrase> {
    try {
        const keyPairFromMnemonic = await mnemonicToKeyPair(words);
        const privateKey = TonWeb.utils.bytesToBase64(keyPairFromMnemonic.secretKey.slice(0, 32));
        const keyPair = nacl.sign.keyPair.fromSeed(TonWeb.utils.base64ToBytes(privateKey));
        const WalletClass = ton.wallet.all['v3R2'];
        const walletContract = new WalletClass(ton.provider, {
            publicKey: keyPair.publicKey,
            wc: 0
        });

        return {
            address: (await walletContract.getAddress()).toString(true, true, false),
            publicKey: keyPair.publicKey.join(','),
            privateKey: keyPairFromMnemonic.secretKey
        }
    } catch (e) {
        throw e
    }
}

export async function encrypt(text: string, password: string): Promise<string> {
    const pwUtf8 = new TextEncoder().encode(password);                                 // encode password as UTF-8
    const pwHash = await crypto.subtle.digest('SHA-256', pwUtf8);                      // hash the password

    const iv = crypto.getRandomValues(new Uint8Array(12));                             // get 96-bit random iv

    const alg = {name: 'AES-GCM', iv: iv};                                           // specify algorithm to use

    const key = await crypto.subtle.importKey('raw', pwHash, alg, false, ['encrypt']); // generate key from pw

    const ptUint8 = new TextEncoder().encode(text);                               // encode plaintext as UTF-8
    const ctBuffer = await crypto.subtle.encrypt(alg, key, ptUint8);                   // encrypt plaintext using key

    const ctArray = Array.from(new Uint8Array(ctBuffer));                              // ciphertext as byte array
    const ctStr = ctArray.map(byte => String.fromCharCode(byte)).join('');             // ciphertext as string
    const ctBase64 = btoa(ctStr);                                                      // encode ciphertext as base64

    const ivHex = Array.from(iv).map(b => ('00' + b.toString(16)).slice(-2)).join(''); // iv as hex string

    return ivHex + ctBase64
}

export async function decrypt(ciphertext: string, password: string): Promise<string> {
    const pwUtf8 = new TextEncoder().encode(password);                                  // encode password as UTF-8
    const pwHash = await crypto.subtle.digest('SHA-256', pwUtf8);                       // hash the password

    const iv = ciphertext.slice(0, 24).match(/.{2}/g)?.map(byte => parseInt(byte, 16));   // get iv from ciphertext

    if (!iv) {
        throw Error()
    }

    const alg = {name: 'AES-GCM', iv: new Uint8Array(iv)};                            // specify algorithm to use

    const key = await crypto.subtle.importKey('raw', pwHash, alg, false, ['decrypt']);  // use pw to generate key

    const ctStr = atob(ciphertext.slice(24));                                           // decode base64 ciphertext
    const ctUint8 = new Uint8Array((ctStr.match(/[\s\S]/g) || []).map(ch => ch.charCodeAt(0))); // ciphertext as Uint8Array
    // note: why doesn't ctUint8 = new TextEncoder().encode(ctStr) work?

    const plainBuffer = await crypto.subtle.decrypt(alg, key, ctUint8);                 // decrypt ciphertext using key

    return new TextDecoder().decode(plainBuffer);     // return the plaintext
}

export const makeSnakeCells = (bytes: Uint8Array) => {
    const ROOT_CELL_BYTE_LENGTH = 35 + 4;
    const CELL_BYTE_LENGTH = 127;
    const root = new TonWeb.boc.Cell();
    root.bits.writeBytes(bytes.slice(0, Math.min(bytes.length, ROOT_CELL_BYTE_LENGTH)));

    const cellCount = Math.ceil((bytes.length - ROOT_CELL_BYTE_LENGTH) / CELL_BYTE_LENGTH);
    if (cellCount > 16) {
        throw new Error('Text too long');
    }

    let cell = root;
    for (let i = 0; i < cellCount; i++) {
        const prevCell = cell;
        cell = new TonWeb.boc.Cell();
        const cursor = ROOT_CELL_BYTE_LENGTH + i * CELL_BYTE_LENGTH;
        cell.bits.writeBytes(bytes.slice(cursor, Math.min(bytes.length, cursor + CELL_BYTE_LENGTH)));
        prevCell.refs[0] = cell;
    }

    return root;
}