(global as any).BN = require('bignumber.js');
(global as any).BN.add = BN.plus;
(global as any).BN.sub = BN.minus;
(global as any).BN.mul = BN.times;
(global as any)._ = require('lodash');
(global as any).ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
(global as any).UINT256_MAX = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

(global as any).parseJWT = (token: string) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

(global as any).getMaskedValue = (val: string, front = 4, mask = 3, back = 2) => {
    if(val.length < 10) {
        return val
    }

    return val.slice(0, front) + '.'.repeat(mask) + val.slice(val.length - back, val.length)
}

(global as any).addComma = (num: number | string) => {
    if(!num) return '0';

    const commaRegex = /\B(?=(\d{3})+(?!\d))/g;
    const parts = String(num).split('.');

    return parts[0].replace(commaRegex, ',') + (parts[1] ? '.' + parts[1] : '');
};

(global as any).abbreviateNumber = (val: number | string, prec = 1) => {
    let value: number = Number(val)

    if (Number.isNaN(value)) {
        return '-'
    }

    let newValue: number | string = value;

    if (BN(value).gte(BN(100000))) {
        let suffixes = ["", "K", "M"];
        let suffixNum = Math.floor( (""+value).length/3 );
        let shortValue: string | number = 0;
        let overflow = (suffixes.length - 1) < suffixNum

        if (overflow) {
            if ((""+value).dprec(0).length >= 10) {
                suffixNum = (suffixes.length)
                suffixes.push('B')
            } else {
                suffixNum = (suffixes.length - 1)
            }
        }

        for (let precision = 2; precision >= prec; precision--) {
            shortValue = parseFloat( String((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value))
                // .toPrecision(precision)
            );
            let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }

        if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(prec);

        if (overflow) {
            shortValue = addComma(shortValue)
        }

        newValue = shortValue+suffixes[suffixNum];
    } else if (newValue) {
        newValue = addComma(String(newValue)).dprec(0)
    }

    return newValue;
}

export function isPromise(p: any) {
    return typeof p === 'object' && typeof p.then === 'function';
}