import {useCallback, useEffect, useRef, useState} from 'react';
import TonWeb from "tonweb";

export default function useTonCoinBalance(ton: TonWeb, address?: string): [number, () => Promise<void>] {
    const [balance, setBalance] = useState<number>(0);
    const prevBalanceRef = useRef<number>(0);

    const fetchBalance = useCallback(async () => {
        let rawBalance: number = 0;

        if (address) {
           rawBalance = await ton.provider.getBalance(address);
        }

        if (rawBalance !== prevBalanceRef.current) {
            prevBalanceRef.current = rawBalance;
            setBalance(rawBalance);
        }
    }, [address, ton.provider]);

    useEffect(() => {
        fetchBalance().then();
    }, [fetchBalance, address]);

    useEffect(() => {
        const interval = setInterval(fetchBalance, 5000)

        return () => clearInterval(interval)
    })

    return [balance, fetchBalance];
}