import React from 'react';
import './CircularProgress.scss';
import styled from "styled-components";

function hexToRgba(hex: string, alpha: number = 1): string | null {
    // HEX 코드에서 숫자와 알파벳을 추출합니다.
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    // 16진수를 10진수로 변환하여 RGB 값을 얻습니다.
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
        return null;
    }

    const r = parseInt(result[1], 16) || 0;
    const g = parseInt(result[2], 16) || 0;
    const b = parseInt(result[3], 16) || 0;

    // RGBA 객체를 반환합니다.
    // return { r, g, b, a: alpha };
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
interface CircularProgressProps {
    size: number;
    color?: string;
    strokeWidth: number;
}

export const CircularProgressComponent = styled.svg`
`

const CircularProgress: React.FC<CircularProgressProps> =
    ({
         size,
         strokeWidth,
         color = '#6BE6FF'
     }) => {
        const radius = (size - strokeWidth) / 2;
        const circumference = 2 * Math.PI * radius;

        const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;
        const maskId = `mask-${Math.random().toString(36).substr(2, 9)}`;

        const startColor = hexToRgba(color, 1)!
        const endColor = hexToRgba(color, 0)!

        return (
            <CircularProgressComponent width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <defs>
                    <linearGradient id={gradientId} gradientUnits="userSpaceOnUse" x1="0%" y1="50%" x2="100%" y2="50%">
                        <stop offset="0%" stopColor={startColor} />
                        <stop offset="100%" stopColor={endColor} />
                    </linearGradient>
                    <mask id={maskId}>
                        <rect x="0" y="0" width={size} height={size} fill="white" />
                        <circle cx={size / 2} cy={size / 2} r={radius - strokeWidth / 2} fill="black" /> {/* 수정된 부분 */}
                    </mask>
                </defs>
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke={`url(#${gradientId})`}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    fill="none"
                    strokeDasharray={`${circumference * 1.05} ${circumference - circumference * 1.05}`}
                    className="circle"
                    mask={`url(#${maskId})`}
                />
            </CircularProgressComponent>
        );
    };

export default CircularProgress;