import {Navigate} from "react-router-dom";

export function RouteWithPassPhrase({ passPhrase, children }: { passPhrase?: string, children: JSX.Element }): JSX.Element {
    if (!passPhrase) {
        return <Navigate to={'/welcome'} replace/>
    }

    return children
}

export default function RouteWithWallet({ address, children }: { address?: string, children: JSX.Element }): JSX.Element {
    if (!address) {
        return <Navigate to={'/sign-in'} replace/>
    }

    return children
}