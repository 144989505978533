import {useMemo} from "react";
import useSubPath from "./useSubPath";

export default function useNavigationButton(): useNavigationButtonReturns {
    const { route, path, subPath } = useSubPath()

    const { back, close } = useMemo(() => {
        switch (route) {
            case 'wallet': {
                switch(path) {
                    case 'create': {
                        return {
                            back: true,
                            close: true
                        }
                    }
                    case 'import': {
                        return {
                            back: true,
                            close: true
                        }
                    }
                    case 'asset': {
                        return {
                            back: subPath.length > 0,
                            close: true
                        }
                    }
                    case 'tx': {
                        switch (subPath[0]) {
                            case 'sent':
                                return {
                                    back: false,
                                    close: true
                                }
                            default:
                                return {
                                    back: true,
                                    close: true
                                }
                        }
                    }
                    default:
                        return {
                            back: false,
                            close: true
                        }
                }
            }
            default:
                return {
                    back: false,
                    close: true
                }
        }
    }, [route, path, subPath])

    return {
        back, close
    }
}