import * as bip39 from "bip39";
import TonWeb from "tonweb";

export function validatePassPhrase(word: string) {
    return bip39.wordlists.english.includes(word.toLowerCase());
}

export function validateAddress(address: string) {
    return TonWeb.Address.isValid(address)
}

export function validatePayload(payload: string) {
    return false
}

export function validatePassword(word: string): PasswordValidationResult {
    let wordLength = word.length;

    if (wordLength < 4) {
        return {
            valid: false
        }
    }

    // 조건 2: 영문 대/소문자, 숫자, 특수문자 중 2가지 이상 조합
    let count = 0;
    let difficulty: "low" | "high" | "medium" = "low";

    // 대소문자 확인
    if (/[A-Za-z]/.test(word)) count++;

    // 숫자 확인
    if (/\d/.test(word)) count++;

    // 특수문자 확인

    // eslint-disable-next-line
    if (/[\!\@\#\$\%\^\&\*\(\)\_\=\+\[\{\}\]\\\|\;\:\'\"\,\<\.\>\/\?\~]/.test(word)) count++;

    if (wordLength >= 8) {
        if (wordLength >= 12 && count === 3) {
            difficulty = "high"
        } else if (count >= 2) {
            difficulty = "medium"
        }
    }

    return {
        valid: true,
        difficulty: difficulty
    }
}