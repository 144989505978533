import styled, {css} from "styled-components";

export const Heading = styled.strong`
  color: ${ ({theme}) => theme.colors.primary };
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: ${ ({theme}) => theme.fonts.ultra };
  line-height: normal;
`

export const SubTextStyle = css`
  color: ${ ({theme}) => theme.colors.subText };
  font-size: 14px;
  font-style: normal;
  font-weight: ${ ({theme}) => theme.fonts.light };
  line-height: normal;
`

export const SubText = styled.span`
  ${SubTextStyle}
`

export const PhraseWordStyle = css`
  color: ${ ({theme}) => theme.colors.text };
  font-weight: ${ ({theme}) => theme.fonts.medium };
  white-space: pre;
`

export const PhraseWord = styled(SubText)`
  ${PhraseWordStyle}
`

export const ImpactSubTextStyle = css`
  color: ${ ({theme}) => theme.colors.text };
  font-weight: ${ ({theme}) => theme.fonts.ultra };
`

export const DescriptionText = styled(SubText)`
  font-size: 12px;
`

export const ImpactSubText = styled(SubText)`
  ${ImpactSubTextStyle}
`

export const ImpactPrimarySubText = styled(ImpactSubText)`
  color: ${ ({theme}) => theme.colors.primary };
`

export const WarningText = styled(ImpactSubText)<{
  withIcon?: boolean
}>`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  
  //white-space: pre;
  font-size: 14px;
  color: ${ ({ theme }) => theme.colors.warning };
  
  ${ ({ withIcon }) => withIcon ? css`
    ::before{
      content: '';
      display: block;

      width: 12px;
      min-width: 12px;
      height: 9px;

      margin-top: 4px;
      background: url("/images/icon/ic-warning.png") no-repeat;
      background-size: contain;
    }
  ` : null}
`