import styled, {css} from "styled-components";
import {ImpactSubTextStyle, SubTextStyle} from "./Texts";
import {CircularProgressComponent} from "../CircularProgress";

export const _defaultButtonSmall = styled.button`
  width: 100%;
  min-width: 100px;
  height: 30px;
  min-height: 30px;

  border-radius: 5px;
  ${SubTextStyle};
`
export const _defaultButtonLarge = styled.button`
  width: 100%;
  max-width: 250px;
  height: 50px;

  border-radius: 5px;
  ${SubTextStyle};
  font-size: 16px;
`

export const PrimaryButton = styled(_defaultButtonLarge)<{
  disabled?: boolean
}>`
  ${ImpactSubTextStyle};
  color: ${ ({theme}) => theme.colors.button.textPrimary };
  background-color: ${ ({theme}) => theme.colors.button.backgroundPrimary };
  
  // opacity: ${ ({ disabled }) => disabled ? 0.1 : 1 };
  
  ${({disabled}) => disabled ? css`
    opacity: 0.1;
    user-select: none;
  ` : undefined };
  
  &:active{
    background-color: ${ ({theme}) => theme.colors.button.hoverPrimary };
  }

  >${CircularProgressComponent}{
    position: relative;
  }
`

const SecondaryButtonStyle = css`
  ${ImpactSubTextStyle};
  color: ${ ({theme}) => theme.colors.button.textSecondary };

  background-color: ${ ({theme}) => theme.colors.button.backgroundSecondary };
  
  &:active{
    background-color: ${ ({theme}) => theme.colors.button.hoverSecondary };
  }
`

export const SecondaryButton = styled(_defaultButtonLarge)`
  ${SecondaryButtonStyle}
`

export const SecondaryButtonSmall = styled(_defaultButtonSmall)`
  ${SecondaryButtonStyle};
  //font-weight: ${ ({theme}) => theme.fonts.light };
`

export const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  
  background: url("/images/icon/ic-close.svg") no-repeat;

  position: absolute;
  
  right: 20px;
  top: 20px;
  
  padding: unset;
  z-index: 1;
`

export const BackButton = styled(CloseButton)`
  background: url("/images/icon/ic-back.png") no-repeat;

  right: unset;
  left: 20px;
`