import i18n from 'i18next'
import {initReactI18next} from "react-i18next";
import {api} from "../utils/api";

const fallbackLang = 'en';

const getJsonUrl = () => process.env.REACT_APP_OPERATOR_TRANSLATE_URL

export const loadTranslations = async () => {
    let json

    try {
        let {data} = await api.get(getJsonUrl()+'?time='+new Date().getTime())
        let tmp = data
        tmp["kr"] = tmp["ko"]
        delete tmp["ko"]
        json = tmp

    } catch (e) {
        console.error(e)
        return null
    }

    return json
}

export const initTranslation = async () => {
    let translations = await loadTranslations()

    return i18n
        // .use(new LanguageDetector(null, {}))
        .use(initReactI18next)
        .init({
            resources: translations,
            supportedLngs: ['en'],
            debug: false,
            keySeparator: false,
            nsSeparator: '.',
            defaultNS: 'General',
            interpolation: {
                escapeValue: true,
                prefix: "{{",
                suffix: "}}"
            },
            fallbackLng: fallbackLang,
            // returnObjects: true,
            react: {
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', 'img', 'div']
            }
        })
}