import TonWeb from 'tonweb';
import {createContext, Dispatch, ReactNode, useContext, useMemo, useReducer} from 'react';
import {encrypt, getKeyPair} from "../utils/crypto";
import {api, RetryRequest} from "../utils/api";
import {WalletContract} from "../types/tonweb";
import useTonCoinBalance from "../hooks/useTonCoinBalance";

export type WalletContextAction =
    "loadWallet" | "setWallet" | "setBalance" | "updateRequest" | "logout" | "initialize" | "setError"

type WalletState = {
    initialized: boolean;
    sessionExpired: boolean;
    error?: ErrorData;

    address?: string
    balance?: string;
    walletContract?: WalletContract;
    encrypted?: string;
    referralCode?: string;
    messageId?: number;
    chatInstance?: string
}

const initialState: WalletState = {
    sessionExpired: false,
    initialized: false
    // address: 'EQCzcGnPuv0IQF_z3DIPNUZOZ9zdogX7wDeLQzlQksEcFlQ2',
    // balance: '7712389578423'
}

type UpdateState = {
    type: WalletContextAction;
    value: Partial<WalletState> | any
}

export async function getWallet(ton: TonWeb, dispatch: Dispatch<UpdateState>, messageId: number, webApp: useWebAppValues, chatInstance: string | undefined | null) {
    try {
        let response = await api.get('/auth/checkSession?' + webApp.initData, {
            headers: {
                messageId: messageId
            }
        })

        if (messageId > -1) {
            dispatch({
                type: 'initialize',
                value: {
                    messageId: messageId,
                    chatInstance: chatInstance
                }
            })
        }

        if (response.data?.passphrase) {
            return {
                passphrase: response.data.passphrase,
                address: response.data.address,
                referralCode: response.data.referralCode
            }
        }
    } catch (e: any) {
        console.error(e)

        if (e.response.status === 401) {
            if (e.response.data === 'Invalid hash') {
                dispatch({
                    type: "setError",
                    value: {
                        type: 'BAD_REQUEST'
                    }
                })
            } else {
                dispatch({
                    type: 'logout', value: {}
                })
                RetryRequest(e.response.request, webApp).then()
            }
        } else {
            dispatch({
                type: "setError",
                value: {
                    type: 'BAD_REQUEST'
                }
            })
        }
    }
}

export async function registerWallet(ton: TonWeb, dispatch: Dispatch<UpdateState>, messageId: number, words: string[], password: string, webApp: useWebAppValues, reset: boolean) {
    try {
        let encrypted = await encrypt(words.join(','), password)
        let keyPair = await getKeyPair(ton, words);

        const path = reset ? 'updateKey' : 'registerKey'

        let response = await api[reset ? 'put' : 'post'](`/auth/${path}?` + webApp.initData, {
            params: {
                address: keyPair?.address,
                publicKey: keyPair?.publicKey,
                passphrase: encrypted,
                uid: webApp?.initDataUnsafe?.user?.id
            }
        })

        const succeed = response.status === 200
        
        if (succeed) {
            let wallet = await getWallet(ton, dispatch, messageId, webApp, null);

            if (wallet) {
                dispatch({
                    type: 'loadWallet',
                    value: {
                        address: keyPair?.address,
                        encrypted: encrypted,
                        referralCode: wallet?.referralCode
                    }
                })
            }

            return true
        }
    } catch (e: any) {
        console.error(e)

        if (e.response.status === 401) {
            if (e.response.data === 'Invalid hash') {
                dispatch({
                    type: "setError",
                    value: {
                        type: 'BAD_REQUEST'
                    }
                })
            } else {
                dispatch({
                    type: 'logout', value: {}
                })
                RetryRequest(e.response.request, webApp).then()
            }
        } else {
            dispatch({
                type: "setError",
                value: {
                    type: 'BAD_REQUEST'
                }
            })
        }
    }
}

function walletReducer(ton: TonWeb, cached: typeof initialState, action: UpdateState): WalletState {
    switch (action.type) {
        case "setError":
            return {
                ...cached,
                error: action.value,
            }
        case "initialize":
            return {
                ...cached,
                error: undefined,
                messageId: cached.messageId ?? action.value.messageId,
                chatInstance: cached.chatInstance ?? action.value.chatInstance
            }
        case "setWallet":
            return  {
                ...cached,
                initialized: true,
                encrypted: action.value.encrypted,
                referralCode: action.value.referralCode
            }
        case "loadWallet":
            return {
                ...cached,
                address: action.value.address,
                encrypted: action.value.encrypted ? action.value.encrypted : cached.encrypted,
                // @ts-ignore
                walletContract: new ton.wallet.all.v3R2(ton.provider, {
                    address: action.value.address,
                    wc: 0
                }),
                referralCode: cached.referralCode ?? action.value.referralCode
            }
        case "setBalance":
            return cached
        case "updateRequest":
            return cached
        case "logout":
            return {
                ...cached,
                error: undefined,
                sessionExpired: true
            }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

export type WalletContextValue = {
    state: WalletState;
    ton: TonWeb;
    tonCoinBalance: number,
    dispatch: Dispatch<UpdateState>;
}

export const WalletContext = createContext<WalletContextValue>({ state: initialState, ton: new TonWeb(), tonCoinBalance: 0, dispatch: () => null });

export default function WalletProvider({ endpoint, children }: { endpoint: string, children: ReactNode }) {
    const ton: TonWeb = useMemo(() => {
        return new TonWeb(new TonWeb.HttpProvider(endpoint));
    }, [endpoint])
    const [state, dispatch] = useReducer(walletReducer.bind(this, ton), initialState)
    const [balance, ] = useTonCoinBalance(ton, state.address)

    const value = {
        state,
        tonCoinBalance: balance,
        ton,
        dispatch,
    };

    return (
        <WalletContext.Provider value={value}>
            {children}
        </WalletContext.Provider>
    );
}

export function useWalletContext() {
    return useContext(WalletContext);
}