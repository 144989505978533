import styled from "styled-components";
import {Heading, ImpactSubText, SubText} from "../components/common/Texts";
import {_defaultButtonLarge} from "../components/common/Buttons";

export const RootLayout = styled.div`
  width: 100%;
  //max-height: 100vh;
  min-height: 100vh;
  max-height: 100vh;

  display: flex;
  flex-direction: row;
  position: relative;
  
  .page-container{
    width: 100%;
    overflow-x: hidden;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }
`

export const PageLayout = styled.div<{
    previous?: boolean;
    padding?: string;
}>`
  min-width: 100vw;
  min-height: 100vh;

  padding: 0 ${ ({padding}) => padding ?? '15%' };
  
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  position: relative;
  
  >:nth-child(1){
    &${Heading}{
      margin-top: 50px;
    }
  }
  
  >:nth-child(2){
    &${SubText}{
      margin-top: 20px;
      text-align: center;
    }
  }
  
  >:last-child{
    &${_defaultButtonLarge}{
      margin-bottom: 50px;
      margin-top: auto;
    }
  }
`

export const PageWithButtonLayout = styled.div<{
    previous?: boolean;
    padding?: string;
}>`
  min-width: 100vw;
  //min-height: 100vh;
  max-height: calc(100vh - 180px);
  overflow-y: auto;

  padding: 0 ${ ({padding}) => padding ?? '15%' };
  padding-bottom: 0;
  margin-top: 80px;
  
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  position: relative;
  
  >:nth-child(1){
    &${Heading}{
      top: 50px;
      position: fixed;
    }
  }
  
  >:nth-child(2){
    &${SubText}{
      margin-top: 20px;
      text-align: center;
    }
  }
  
  >:last-child{
    &${_defaultButtonLarge}{
      position: fixed;
      bottom: 20px;
      //margin-bottom: 50px;
      //margin-top: auto;
    }
  }
`

export const AlignWithIndex = styled.div<{
    left?: string | number
}>`
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden;

  >${ImpactSubText}{
    min-width: ${ ({ left }) => left ? (
            typeof left === 'string' ? left : `${left}px`
    ) : '30%' };

    text-align: left;
  }
  
  >:last-child{
    overflow-x: hidden;
  }
`